import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fabric } from 'fabric';
import Slider from 'rc-slider';
import { updateJsonState } from './utils/utils';
import CustomCanvas from './utils/custom_canvas';
import { CUSTOM_POLY } from './utils/constants';
import { setJsonState, setLastColor } from './reducers/actions';
import ColorPicker from './inputs/color_picker/color_picker';


export class ShapeEditControls extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    popupOpen: PropTypes.bool,
    selected: PropTypes.arrayOf(fabric.Object),
    setJsonState: PropTypes.func,
    setLastColor: PropTypes.func,
  };

  getSelectedShapes = () => {
    const answer = new Set();
    this.props.selected.forEach((obj) => {
      if (!obj.customType) {
        answer.add(obj);
      } else if (obj.customType === CUSTOM_POLY) {
        answer.add(obj.polyObj.poly);
      }
    });
    return [...answer];
  }

  state = {
    selectedColor: "#0EAA0A",
    value: this.getSelectedShapes()[0].strokeWidth * 5,
    fillColor: 'black',
    strokeColor: 'black',
    initialFills: [],
    hasPopoverOpen: false
  }

  componentDidMount() {
    this.setState({ fillColor: this.getSelectedShapes()[0].fill });
    this.setState({ strokeColor: this.getSelectedShapes()[0].stroke });

  }

  getRGBA = (color) => {
    return `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
  }

  onBorderColorChange = (color) => {
    if (this.props.selected.length === 0) {
      return;
    }

    const newColor = this.getRGBA(color);

    for (const obj of this.getSelectedShapes()) {
      obj.set({ stroke: newColor });
    }
    this.setState({ strokeColor: newColor });
    this.props.setLastColor(this.state.strokeColor);

    this.props.canvas.renderAll();
  }

  onFillColorChange = (color) => {
    if (this.props.selected.length === 0) {
      return;
    }

    const newColor = this.getRGBA(color);

    for (const obj of this.getSelectedShapes()) {
      obj.set({ fill: newColor });

    }
    this.setState({ fillColor: newColor });
    this.props.setLastColor(this.state.fillColor);

    this.props.canvas.renderAll();
  }

  onSliderChange = (value) => {
    let e;
    for (e = 0; e < this.getSelectedShapes().length; e++) {
      if (typeof (this.getSelectedShapes()[e].strokeWidth) !== 'undefined') {
        this.getSelectedShapes()[e].set({ strokeWidth: value / 5 });
      }
    }
    this.setState({ value, });
    this.props.canvas.renderAll();
  }

  onAfterSliderChange = () => {
    updateJsonState.call(this);
  }

  polygonControls = () => {
    if (this.props.selected.length === 1 && this.props.selected[0].customType === CUSTOM_POLY) {
      return (
        <>
          <p>You can double click on a polygon to edit its points</p>
        </>
      )
    } else {
      return null;
    }
  }

  render() {    
    return (
      <div>
        <div >
          <h5><b>Fill Color</b></h5>
          <ColorPicker
            disabled={this.state.hasPopoverOpen}
            name="setup-fill-btn"
            value={this.getSelectedShapes()[0].fill}
            onChange={this.onFillColorChange}
            onClose={() => this.setState({ hasPopoverOpen: false })}
            onOpen={() => this.setState({ hasPopoverOpen: true })}
          />
        </div>
        <div>
          <h5><b>Border Color</b></h5>
          <ColorPicker
            disabled={this.state.hasPopoverOpen}
            name="setup-stroke-btn"
            value={this.getSelectedShapes()[0].stroke}
            onChange={this.onBorderColorChange}
            onClose={() => this.setState({ hasPopoverOpen: false })}
            onOpen={() => this.setState({ hasPopoverOpen: true })}
          />
        </div>
        <div>
          <h5>Stroke Width:</h5>
          <Slider
            disabled={this.props.popupOpen}
            handleStyle={{ 'borderColor': '#1863A3' }}
            trackStyle={{ 'backgroundColor': '#1863A3' }}
            value={this.state.value}
            onAfterChange={this.onAfterSliderChange}
            onChange={this.onSliderChange}
          />
        </div>

        <div>
          {this.polygonControls()}
        </div>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    selected: state.selected,
    canvas: state.canvas,
    jsonState: state.jsonState,
    popupOpen: state.popupOpen
  };
}
const mapDispatchToProps = { setJsonState, setLastColor };
export default connect(mapStateToProps, mapDispatchToProps)(ShapeEditControls);
